import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(private http: HttpClient) { }










  AddSchoolGst(schoolid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolGst/' + schoolid, data);
  }









  // UploadSchoolGst(schoolid: string, applicable: string, academic: string, data: any) {
  //   return this.http.post(environment.apiUrl + 'Center/' + 'UploadSchoolGst/' + schoolid + '/' + applicable + '/' + academic , data);
  // }

  UploadSchoolGst(schoolid: string, applicable: string, academic: string, fileToUpload: File) {
    console.log(fileToUpload);
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadSchoolGst/' + schoolid + '/' + applicable + '/' + academic, formData);
  }

  GetSchoolSurveyList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolSurveyList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  AddSchoolSurvey(UserId: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolSurvey/' + UserId, data);
  }

  GetSchoolSurvey(surverid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolSurvey/' + surverid);
  }

  UpdateSchoolSurvey(surverid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolSurvey/' + surverid, data);
  }



  GetSchoolStandard(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolStandard/' + schoolid);
  }
  AddCALBatch(schoolid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALBatch/' + schoolid, data);
  }


  GetRptATALSchoolWeekAttendance(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALSchoolWeekAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }



}
