import { Component, OnInit ,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CenterService } from 'src/app/shared/services/center.service';
import { JankariportalpartnerService } from 'src/app/shared/services/jankariportalpartner.service';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class contact {
  public empid : string = "";
  public degree : string = "";
  public university: string = "";
  public year: string = "";
  public classes: string = "";
  public updateby: string = "";
}

@Component({
  selector: 'app-addnewqualificationpopup',
  templateUrl: './addnewqualificationpopup.component.html',
  styleUrls: ['./addnewqualificationpopup.component.scss']
})
export class AddnewqualificationpopupComponent implements OnInit {
  addqualificationform: FormGroup;
  UserId;
  pgname;
  Userrole;
  pgid;
  employee;
  loading = false;
  submited = new EventEmitter<string>();
  Qualificationlist;
  degree ="";
  classes = "";
  constructor(public dialog: MatDialog, private toastr: ToastrService, private service: JankariportalpartnerService, private centerservice: CenterService, public fb: FormBuilder, private router: Router) {
    this.UserId = localStorage.getItem("UserId");
    this.pgname = localStorage.getItem("pgname");
    this.Userrole = localStorage.getItem("role");
    this.pgid = localStorage.getItem("pgid");

    this.centerservice.FillDropDown("QualificationMaster", "distinct qualificationname", "qualificationname", " ").subscribe((data: any) => {
      this.Qualificationlist = data;
    });


  }

  ngOnInit() {
    this.addqualificationform = this.fb.group({
      degree: ['', Validators.required],
      university: ['', Validators.required],
      year: ['', Validators.required],
      classes: ['', Validators.required],
  });
  }

  Addqulificationdetail() {
    this.loading = true;
    const contactdetails: contact = new contact();
    contactdetails.empid = this.UserId;
    contactdetails.degree = this.addqualificationform.value.degree;
    contactdetails.university = this.addqualificationform.value.university;
    contactdetails.year = this.addqualificationform.value.year;
    contactdetails.classes = this.addqualificationform.value.classes;
   
    console.log(contactdetails);
    this.service.InsertEmployeeQualification(contactdetails).subscribe((res) => {
      this.submited.emit(res.toString());
      console.log(res.toString());
      this.toastr.success("Added SuccessFully");
    this.dialog.ngOnDestroy();
    this.router.navigateByUrl('/MyJankari', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/JankariEmpDetail']));
    this.loading = false;
    },
      error => {
        console.log(error);
      });
  }

 

  clear() {
    this.dialog.ngOnDestroy();
  }

}
