import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';


@Component({
  selector: 'app-attendancewarningpopupidi',
  templateUrl: './attendancewarningpopupidi.component.html',
  styleUrls: ['./attendancewarningpopupidi.component.scss']
})
export class AttendancewarningpopupidiComponent implements OnInit {

  constructor(public dialog: MatDialog ,public card:MatCardModule) {
 
    
  }

  ngOnInit() {
  }

}
