import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/user';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/ClassFiles/city';
import { State } from 'src/app/ClassFiles/state';
import { Distirct } from 'src/app/ClassFiles/distirct';
import { Userr } from 'src/app/ClassFiles/userr';
import { EmployeeNameList } from 'src/app/ClassFiles/employee-name-list';
import { Departmentlist } from 'src/app/ClassFiles/departmentlist';
import { Userrole } from 'src/app/ClassFiles/userrole';

@Injectable({
  providedIn: 'root'
})
export class JankariportalpartnerService {

  city: City[];
  state: State[];
  distirct: Distirct[];
  Departmentlist: Departmentlist[];
  Userrole: Userrole[];

  constructor(private http: HttpClient) { }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  InsertUserInfo(UserDeviceInfo: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertUserInfo', UserDeviceInfo);
  }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  login(user: User) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'Login', JSON.stringify(user), { headers: headers });
  }

  GetEmployeeList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeList?whr=' + btoa(where));
  }

  GetUserProgramLoginList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetUserProgramLoginList?whr=' + btoa(where));
  }

  BirthdayNotification() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'BirthdayNotification');
  }

  HomeSummary() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'HomeSummary');
  }

  GetName(table: string, col: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetName?table=' + table + '&col=' + btoa(col) + '&whr=' + btoa(where));
  }

  GetProgramWiseEmployeeRole(pgid: string, userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetProgramWiseEmployeeRole/' + pgid + '/' + userid);
  }

  getMenus(userId, pgid, roleid, MenuId, typeid) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'getMenus/' + userId + '/' + pgid + '/' + roleid + '/' + MenuId + '/' + typeid);
  }

  GetEmployeeReport(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetRptEmployee?whr=' + btoa(where));
  }

  GetProfile(userid: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetProfile/' + userid + '/' + pgid);
  }

  UpdatePassword(userid: string, otp: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdateOTPPassword/' + userid + '/' + otp, data);
  }

  InsertUserActivity(user: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertUserActivity', user);
  }

  GetJD(desid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetJD/' + desid);
  }

  RemoveEmployeeDocument(empid: string, financialyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'RemoveEmployeeDocument/' + empid + '/' + financialyear);
  }

  GetEmployeeDocumentNew(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeDocumentNew/' + empid);
  }

  UploadEmployeeDocument(empid: string, fineyear: string, documenttype: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Employee/' + 'UploadEmployeeDocument/' + empid + '/' + fineyear + '/' + documenttype, formData);
  }

  RemoveEmployeeReference(refid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveEmployeeReference/' + refid);
  }

  GetEmployeeReference(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeReference/' + empid);
  }

  InsertEmployeeReference(EmpR: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertEmployeeReference', EmpR);
  }

  GetEmployeeQualification(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeQualification/' + empid);
  }

  RemoveEmployeeQualification(quliid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveEmployeeQualification/' + quliid);
  }

  InsertEmployeeQualification(EmpQ: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertEmployeeQualification', EmpQ);
  }

  RemoveEmployeeProgram(efid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveEmployeeProgram/' + efid);
  }
  GetEmployeeProgram(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeProgram/' + empid);
  }

  InsertEmployeeProgram(Empprogram: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertEmployeeProgram', Empprogram);
  }

  GetReportstoEmp(desid) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetReportstoEmp/' + desid);
  }

  UpdateEmployee(empid: string, userid: string, employee: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdateEmployee/' + empid + '/' + userid, employee);
  }

  postFile(empid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UploadEMPPhoto/' + empid, formData);

  }

  GetStatewiseDistrictList(stname: string) {
    return this.http.get<Distirct[]>(environment.apiUrl + 'JankariPortalPartner/' + 'StatewiseDistrictList/' + stname);
  }

  GetDistrictwiseCityList(dtname: string) {
    return this.http.get<City[]>(environment.apiUrl + 'JankariPortalPartner/' + 'DistrictwiseCityList/' + dtname);
  }

  GetActiveEmployeeFor(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetActiveEmployeeFor/' + empid);
  }

  GetProgramlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetProgramlist?whr=' + btoa(where));
  }

  GetOfficeList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetOfficeList?whr=' + btoa(where));
  }

  GetHideButton(roleid: string, pgid: string, pagename: string, buttontype: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetHideButton/' + roleid + '/' + pgid + '/' + pagename + '/' + buttontype);
  }

  GetProfileImage(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeProfiePic/' + empid);
  }

  GetEmployeePromotionDetNew(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotionDetNew/' + empid);
  }

  GetEmployeePromotionDetailNew(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotionDetailNew/' + empid);
  }

  GetEmployeeDetails(empid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetEmployeeDetails/' + empid);
  }

  GetStateList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'StateList');
  }

  GetCityList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'CityList');
  }

  GetDistrictList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'DistrictList');
  }

  GetEmployeeDocument(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeDocumentNew/' + empid);
  }

  EmployeeNameList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'EmployeeNameList');
  }

  InsertEmployee(employee: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertEmployee', employee);
  }

  InsertState(state: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertState', state);
  }

  RemoveState(stname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveState/' + stname);
  }

  UserRoleList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'UserRoleList');
  }

  InsertRole(role: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertRole', role);
  }

  RemoveUserrole(roleid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveUserrole/' + roleid);
  }

  InsertOffice(office: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertOffice', office);
  }

  UpdateOffice(offid: string, office: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdateOffice/' + offid, office);
  }

  GetOfficeDetails(offid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetOfficeDetails/' + offid);
  }

  OrganizationList() {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'OrganizationList');
  }

  GetPartnerlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetPartnerlist?whr=' + btoa(where));
  }

  GetPartnerDetails(partnerid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetPartnerDetails/' + partnerid);
  }

  GetPartnerDetail(partnerid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetPartnerDetail/' + partnerid);
  }

  InsertPartner(partner: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertPartner', partner);
  }

  UpdatePartner(partnerid: string, partner: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdatePartner/' + partnerid, partner);
  }

  AddPartnerDetail(partnerid: string, partner: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'AddPartnerDetail/' + partnerid, partner);
  }

  RemovePartnerDetail(pdid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemovePartnerDetail/' + pdid);
  }

  InsertCity(city: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertCity', city);
  }

  RemoveCity(ctname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveCity/' + ctname);
  }

  InsertDistrict(District: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertDistrict', District);
  }

  RemoveDistrict(dtname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveDistrict/' + dtname);
  }

  InsertProgram(Newprog: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertProgram', Newprog);
  }

  RemoveProgram(pgid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveProgram/' + pgid);
  }

  UpdateContactInfo(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdateContactInfo/' + userid, data);
  }

  UpdatePersonalInfo(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdatePersonalInfo/' + userid, data);
  }

  GetUserMasterlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetUserMasterlist?whr=' + btoa(where));
  }

  GetUserProgramDetails(userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetUserProgramDetails/' + userid);
  }

  RemoveUserProgram(upid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'RemoveUserProgram/' + upid);
  }

  InsertUserProgram(program: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertUserProgram', program);
  }

  InsertUserMaster(user: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertUserMaster', user);
  }

  EmployeewiseUsername(empid: string) {
    return this.http.get<Userr[]>(environment.apiUrl + 'JankariPortalPartner/' + 'EmployeewiseUsername/' + empid);
  }

  DepartmentwiseEmployeeList(depid: string) {
    return this.http.get<EmployeeNameList[]>(environment.apiUrl + 'JankariPortalPartner/' + 'DepartmentwiseEmployeeList/' + depid);
  }

  ProgramwiseDepartmentList(pgid: string) {
    return this.http.get<Departmentlist[]>(environment.apiUrl + 'JankariPortalPartner/' + 'ProgramwiseDepartmentList/' + pgid);
  }

  ProgramwiseRoleList(pgid: string) {
    return this.http.get<Userrole[]>(environment.apiUrl + 'JankariPortalPartner/' + 'ProgramwiseRoleList/' + pgid);
  }

  GetRptTraineeAttendance(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetRptTraineeAttendance?whr=' + btoa(whr));
  }

  GetTraineeAttendanceList(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetTraineeAttendanceList?whr=' + btoa(whr));
  }

  GetTrainingExamSummaryCEO(userid: string, role: string, pgid: string, training: string, type: string, language: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetTrainingExamSummaryCEO/' + userid + '/' + role + '/' + pgid + '/' + training + '/' + type + '/' + language + '?whr=' + btoa(where));
  }

  GetUserExam(empid: string, exam: string, type: string, language: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetUserExam/' + empid + '/' + exam + '/' + type + '/' + language);
  }

  GetTrainingExamSummary(userid: string, role: string, pgid: string, training: string, type: string, language: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetTrainingExamSummary/' + userid + '/' + role + '/' + pgid + '/' + training + '/' + type + '/' + language + '?whr=' + btoa(where));
  }

  GetTraineeEmployee(empid: string, date: string, training: string, day: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetTraineeEmployee/' + empid + '/' + date + '/' + training + '/' + day);
  }

  GetTraineeAttendanceData(trattid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'GetTraineeAttendanceData/' + trattid);
  }

  UpdateTraineeAttendance(trattid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'UpdateTraineeAttendance/' + trattid, data);
  }

  AddTraineeAttendance(date: string, training: string, day: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'AddTraineeAttendance/' + date + '/' + training + '/' + day + '/' + userid, data);
  }

  InsertTaineeExamAttempt(empid: string, exam: string, type: string, language: string, data) {
    return this.http.post(environment.apiUrl + 'JankariPortalPartner/' + 'InsertTaineeExamAttempt/' + empid + '/' + exam + '/' + type + '/' + language, data);
  }

  FillCenterCityIDI(us, ur, ogid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'FillCenterCityIDI?us=' + us + '&ur=' + ur + '&ogid=' + ogid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  FillEmpByDepDes(where) {
    return this.http.get(environment.apiUrl + 'JankariPortalPartner/' + 'FillEmpByDepDes?whr=' + btoa(where));
  }

}
