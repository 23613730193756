import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BnNgIdleService } from 'bn-ng-idle';
import { DialogpopupComponent } from './layout/employeemodule/dialogpopup/dialogpopup.component';
import { ForgotpasswordpopupComponent } from './layout/employeemodule/forgotpasswordpopup/forgotpasswordpopup.component';
import { MatInputModule, MatFormFieldModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OtppopupComponent } from './layout/employeemodule/otppopup/otppopup.component';
import { SortheaderPipe } from './shared/services/sortheader.pipe';
import { CreatebatchpopupComponent } from './layout/Schoolmodule/createbatchpopup/createbatchpopup.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UpdateemployeepersonaldetpopupComponent } from './layout/MyJankari/updateemployeepersonaldetpopup/updateemployeepersonaldetpopup.component';
import { UpdatecontactinfopopupComponent } from './layout/MyJankari/updatecontactinfopopup/updatecontactinfopopup.component';
import { AddnewqualificationpopupComponent } from './layout/MyJankari/addnewqualificationpopup/addnewqualificationpopup.component';
import { CreatestandardwisebatchpopupComponent } from './layout/Schoolmodule/createstandardwisebatchpopup/createstandardwisebatchpopup.component';
import { PermissionpopupComponent } from './layout/employeemodule/permissionpopup/permissionpopup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BatchwiseattdetailspopupComponent } from './layout/center/CAl-PIF/Reports/batchwiseattdetailspopup/batchwiseattdetailspopup.component';
import { AttendancewarningpopupComponent } from './layout/center/CAl-PIF/TimeTable/SchoolwisePlanTimeTable/attendancewarningpopup/attendancewarningpopup.component';
import { AttendancewarningpopupidiComponent } from './layout/center/IDI/Timetable/centerwiseplanTT/attendancewarningpopupidi/attendancewarningpopupidi.component';
import { EditstatuspopupComponent } from './layout/center/IDI/IDIstudentmodule/editstatuspopup/editstatuspopup.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule, ReactiveFormsModule, FormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    entryComponents: [DialogpopupComponent, ForgotpasswordpopupComponent, OtppopupComponent, CreatebatchpopupComponent, UpdateemployeepersonaldetpopupComponent, UpdatecontactinfopopupComponent, AddnewqualificationpopupComponent, CreatestandardwisebatchpopupComponent, PermissionpopupComponent, BatchwiseattdetailspopupComponent,AttendancewarningpopupComponent, AttendancewarningpopupidiComponent, EditstatuspopupComponent],
    declarations: [AppComponent, DialogpopupComponent, ForgotpasswordpopupComponent, OtppopupComponent, SortheaderPipe, CreatebatchpopupComponent,  UpdateemployeepersonaldetpopupComponent, UpdatecontactinfopopupComponent, AddnewqualificationpopupComponent, CreatestandardwisebatchpopupComponent, PermissionpopupComponent, BatchwiseattdetailspopupComponent,AttendancewarningpopupComponent, AttendancewarningpopupidiComponent, EditstatuspopupComponent],
    providers: [AuthGuard, BnNgIdleService, DeviceDetectorService],
    bootstrap: [AppComponent],

})
export class AppModule { }
