import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { City } from 'src/app/ClassFiles/city';
import { Distirct } from 'src/app/ClassFiles/distirct';
import { State } from 'src/app/ClassFiles/state';
import { CenterService } from 'src/app/shared/services/center.service';
import { JankariportalpartnerService } from 'src/app/shared/services/jankariportalpartner.service';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class contact {
  public address: string = "";
  public state: string = "";
  public district: string = "";
  public city: string = "";
  public pincode: number = 0;
  public mobile: string = "";
  public altmobile: string = "";
  public email: string = "";
  public updatedby: string = "";
}

@Component({
  selector: 'app-updatecontactinfopopup',
  templateUrl: './updatecontactinfopopup.component.html',
  styleUrls: ['./updatecontactinfopopup.component.scss']
})
export class UpdatecontactinfopopupComponent implements OnInit {
  contactform: FormGroup;
  UserId;
  pgname;
  Userrole;
  pgid;
  bloodgroup;
  employee;
  employeeId;
  loading = true;
  submited = new EventEmitter<string>();
  state: State[];
  dtname: Distirct[];
  ctname: City[];

  constructor(public dialog: MatDialog, private toastr: ToastrService, private service: JankariportalpartnerService, private centerservice: CenterService, public fb: FormBuilder, private router: Router) {
    this.UserId = localStorage.getItem("UserId");
    this.pgname = localStorage.getItem("pgname");
    this.Userrole = localStorage.getItem("role");
    this.pgid = localStorage.getItem("pgid");

    centerservice.FillDropDown("state", "stname", "stname", "").subscribe((data: any) => {
      this.state = data;
    });

    service.GetDistrictList().subscribe((data: any) => { this.dtname = data; })
    service.GetCityList().subscribe((data: any) => { this.ctname = data; })

    service.GetEmployeeDetails(this.UserId).subscribe((data: any) => {
      this.employee = data;
      this.loading = false;
    });


  }

  ngOnInit() {
    this.contactform = this.fb.group({
      stname: ['', Validators.required],
      address: ['', Validators.required],
      district: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
      contactno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      altmobile: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    });
  }

  onSelectState(stname) {
    if (stname == "") {
      this.dtname = null;
    }
    else {
      this.service.GetStatewiseDistrictList(stname).subscribe(
        data => {
          this.dtname = data;
        }
      );
    }
  }

  onSelectDistrict(dtname) {
    if (dtname == "") {
      this.ctname = null;
    }
    else {
      this.service.GetDistrictwiseCityList(dtname).subscribe(
        data => {
          this.ctname = data;
        }
      );
    }
  }

  UpdateContactDetails() {
    this.loading = true;
    const contactdetails: contact = new contact();
    contactdetails.state = this.contactform.value.stname;
    contactdetails.district = this.contactform.value.district;
    contactdetails.city = this.contactform.value.city;
    contactdetails.address = this.contactform.value.address;
    contactdetails.pincode = this.contactform.value.pincode;
    contactdetails.mobile = this.contactform.value.contactno;
    contactdetails.altmobile = this.contactform.value.altmobile;
    contactdetails.email = this.contactform.value.email;
    contactdetails.updatedby = this.UserId;

    this.service.UpdateContactInfo(this.UserId, contactdetails).subscribe((res) => {
      console.log(res);
      this.submited.emit(res.toString());
      this.toastr.success("Update SuccessFully");
      this.dialog.ngOnDestroy();
      this.router.navigateByUrl('/MyJankari', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/JankariEmpDetail']));
      this.loading = false;
    },
      error => {
        console.log(error);
      }
    );
  }

  clear() {
    this.dialog.ngOnDestroy();
  }

}
