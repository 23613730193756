import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CenterService } from 'src/app/shared/services/center.service';
import { JankariportalpartnerService } from 'src/app/shared/services/jankariportalpartner.service';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class personal {
  public prefix: string = "";
  public firstname: string = "";
  public middlename: string = "";
  public lastname: string = "";
  public gender: string = "";
  public maritalstatus: string = "";
  public dob: Date = new Date();
  public pancard: string = "";
  public aadharnumber: string = "";
  public passportno: string = "";
  public bloodgroup: string = "";
  public hobby: string = "";
  public updatedby: string = "";
}

@Component({
  selector: 'app-updateemployeepersonaldetpopup',
  templateUrl: './updateemployeepersonaldetpopup.component.html',
  styleUrls: ['./updateemployeepersonaldetpopup.component.scss']
})
export class UpdateemployeepersonaldetpopupComponent implements OnInit {
  personalform: FormGroup;
  UserId;
  pgname;
  Userrole;
  pgid;
  bloodgroup;
  employee;
  employeeId;
  loading = true;
  submited = new EventEmitter<string>();
  constructor(public dialog: MatDialog, private toastr: ToastrService, private service: JankariportalpartnerService, private centerservice: CenterService, public fb: FormBuilder, private router: Router) {
    this.UserId = localStorage.getItem("UserId");
    this.pgname = localStorage.getItem("pgname");
    this.Userrole = localStorage.getItem("role");
    this.pgid = localStorage.getItem("pgid");

    this.centerservice.FillDropDown("BloodGroup", "distinct bloodgroup", "bloodgroup", "").subscribe((data: any) => {
      this.bloodgroup = data;
    });

    service.GetEmployeeDetails(this.UserId).subscribe((data: any) => {
      this.employee = data;
      this.loading = false;
    });

  }

  ngOnInit() {
    this.personalform = this.fb.group({
      prefix: ['', Validators.required],
      firstname: ['', Validators.required],
      middlename: [''],
      lastname: ['', Validators.required],
      gender: ['', Validators.required],
      maritalstatus: [''],
      dob: ['', Validators.required],
      pancard: ['', [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]],
      aadharnumber: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern('[0-9]*')]],
      passportno: [''],
      bloodgroup: ['', Validators.required],
      hobby: [''],
    });
  }

  UpdatePersonal() {
    this.loading = true;
    const personladetails: personal = new personal();
    personladetails.prefix = this.personalform.value.prefix;
    personladetails.firstname = this.personalform.value.firstname;
    personladetails.middlename = this.personalform.value.middlename;
    personladetails.lastname = this.personalform.value.lastname;
    personladetails.dob = this.personalform.value.dob;
    personladetails.bloodgroup = this.personalform.value.bloodgroup;
    personladetails.maritalstatus = this.personalform.value.maritalstatus;
    personladetails.gender = this.personalform.value.gender;
    personladetails.aadharnumber = this.personalform.value.aadharnumber;
    personladetails.pancard = this.personalform.value.pancard;
    personladetails.passportno = this.personalform.value.passportno;
    personladetails.hobby = this.personalform.value.hobby;
    personladetails.updatedby = this.UserId;

    this.service.UpdatePersonalInfo(this.UserId, personladetails).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toastr.success("Update SuccessFully");
      this.dialog.ngOnDestroy();
      this.router.navigateByUrl('/MyJankari', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/JankariEmpDetail']));
      this.loading = false;
    },
      error => {
        console.log(error);
      }
    );
  }

  clear() {
    this.dialog.ngOnDestroy();
  }

}
